import { Alert, Button, Dialog, Flex, Label, FormSlider, Form, FormDatepicker, FormDropdown, FormRadioGroup, Card, CardBody, FormCheckbox, FormTextArea, FormInput } from "@fluentui/react-northstar"
import { useState } from "react"

import './ResourceRequest.css';

import { useGraph } from "@microsoft/teamsfx-react";
import { useContext } from "react";
import { TeamsFxContext } from "../Context";

import { TeamsFxProvider } from "@microsoft/mgt-teamsfx-provider";
import { Providers, ProviderState } from "@microsoft/mgt-element"

import ResourceRequestGrid from "./ResourceRequestGrid";

import { Person, ProjectAndClient, ResourceItem, Skill } from "../../interface";
import moment from "moment";
import axios from "axios";


const ResourceRequest = (props: any) => {

    const defaultAlert = {
        actions: [
            {
                primary: false,
                text: true,
                content: 'Dismiss',
                onClick: () => setShowAlert(false),
                key: 'dismiss'
            }
        ],
        content: ''
    };

    const [alert, setAlert] = useState<any>(defaultAlert)
    const [project, setProject] = useState<ProjectAndClient>({} as ProjectAndClient)
    const [selectedSkills, setSkills] = useState<Array<string>>(new Array<string>())
    const [selectedLocations, setLocations] = useState<Array<string>>(new Array<string>())
    const [selectedDepartments, setDepartments] = useState<Array<string>>(new Array<string>())
    const [comment, setComment] = useState<string>("");
    const [selectedCompanies, setCompanies] = useState<Array<string>>(new Array<string>())
    const [resourceItems, setResourceItems] = useState(new Array<ResourceItem>())
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [inputChangeTimeout, setInputChangeTimeout] = useState<NodeJS.Timeout>(null as unknown as NodeJS.Timeout);
    const [gridLoading, setGridLoading] = useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const [selectedResourceItems, setSelectedResourceItems] = useState<Array<ResourceItem>>(new Array<ResourceItem>());
    const [startDate, setStartDate] = useState<Date>(new Date())
    const [endDate, setEndDate] = useState<Date>(moment().add(1, 'month').toDate())
    const [weightSliderValue, setWeightSliderValue] = useState<string>('50');
    const [resourceTypeSelection, setResourceTypeSelection] = useState<Array<string>>(['Employee', 'Contractor']);
    const [projectSearchQuery, setProjectSearchQuery] = useState<string>('');
    const [projectSearchOpen, setProjectSearchOpen] = useState<boolean>(false);
    const [timeSelection, setTimeSelection] = useState<string>('Full Time');
    const [hoursPerWeek, setHoursPerWeek] = useState<number>(0)
    const [partTimePercentage, setPartTimePercentage] = useState<string>('50')

    const { teamsfx } = useContext(TeamsFxContext);
    // eslint-disable-next-line
    const { loading, error, data, reload } = useGraph(
        async (graph, teamsfx, scope) => {
            // Call graph api directly to get user profile information
            const profile: any = await graph.api("/me").get();
        
            // Initialize Graph Toolkit TeamsFx provider
            const provider = new TeamsFxProvider(teamsfx, scope);
            Providers.globalProvider = provider;
            Providers.globalProvider.setState(ProviderState.SignedIn);

            let photoUrl = "";
            try {
                const photo = await graph.api("/me/photo/$value").get();
                photoUrl = URL.createObjectURL(photo);
            } catch {
                // Could not fetch photo from user's profile, return empty string as placeholder.
            }



            return { profile, photoUrl };
        },
        { scope: [ "User.Read" ], teamsfx: teamsfx }
    );

    function skillSearchValueChange(event: any, target: any) {
        let selectedSkills = [...target.value]
        setSkills(selectedSkills)
        inputChange(selectedSkills, resourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies);
    }

    function locationSearchValueChange(event: any, target: any) {
        let selectedLocations = [...target.value]
        setLocations(selectedLocations)
        inputChange(selectedSkills, resourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies);
    }

    function departmentSearchValueChange(event: any, target: any) {
        let selectedDepartments = [...target.value]
        setDepartments(selectedDepartments)
        inputChange(selectedSkills, resourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies);
    }
    function companySearchValueChange(event: any, target: any) {
        let selectedCompanies = [...target.value]
        setCompanies(selectedCompanies)
        inputChange(selectedSkills, resourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies);
    }

    function resourceItemSelectedChange(items: Array<ResourceItem>) {
        setSelectedResourceItems(items)
    }
    
    function inputChange(selectedSkills: Array<string>, resourceTypeSelection: Array<string>, selectedLocations: Array<string>, selectedDepartments: Array<string>, selectedCompanies: Array<string>) {

        setGridLoading(true)

        if (inputChangeTimeout !== null) {
            clearTimeout(inputChangeTimeout);
            setInputChangeTimeout(null as unknown as NodeJS.Timeout);
        }

        setInputChangeTimeout(setTimeout(() => {
            
            let possiblePeople = props.people.filter((entry: {persNo: number, person: Person, minutes: Array<number>}) => {
                if (entry.person.skills[0]["Employee Status (Picklist Label)"] === 'Terminated') return false;
                if (selectedLocations.length === 0 && selectedDepartments.length === 0 && selectedCompanies.length === 0 && selectedSkills.length === 0) return false;
                if (selectedLocations.length > 0 && !selectedLocations.some( location =>  entry.person.skills.some(skill => skill["Location (Name)"] === location))) return false;
                if (selectedDepartments.length > 0 && !selectedDepartments.some( department =>  entry.person.skills.some(skill => skill["Department (Label)"] === department))) return false;
                if (selectedCompanies.length > 0 && !selectedCompanies.some( company =>  entry.person.skills.some(skill => skill["Company (Label)"] === company))) return false;
                if ( 
                    (resourceTypeSelection.includes("Employee") && !resourceTypeSelection.includes("Contractor") && entry.person.skills[0]["Employment Type (Picklist Label)"] !== 'Employee')
                    ||
                    (resourceTypeSelection.includes("Contractor") && !resourceTypeSelection.includes("Employee") && entry.person.skills[0]["Employment Type (Picklist Label)"] === 'Employee')
                ) {
                    return false
                }
                
                // if (entry.person.assignments.filter((assignment: Assignment) => {
                //     let splitEnd, parsedEnd;
                //     if (assignment["WBS End Date"].includes('/')) {
                //         splitEnd = assignment["WBS End Date"].split('/');
                //         parsedEnd = new Date(Date.parse(splitEnd[2] + '-' + splitEnd[0] + '-' + splitEnd[1]));
                //     } else {
                //         parsedEnd = new Date(Date.parse(assignment["WBS End Date"]))
                //     }
                //     return parsedEnd > new Date()
                // }).length === 0) {
                //     return false;
                // }

                if (selectedSkills.length > 0 && !selectedSkills.some(skill => entry.person.skills.some((sf_skill: Skill) => {
                    return entry.person.uniqueSkills.includes(skill)
                    // let parsedSkill = (
                    //     sf_skill["Skill (Picklist Label)"] + 
                    //     (sf_skill["Competency (Picklist Label)"] !== '' ? sf_skill["Skill (Picklist Label)"] !== '' ? ' - ' + sf_skill["Competency (Picklist Label)"] : sf_skill["Competency (Picklist Label)"] : '') 
                    //     + (sf_skill["Proficiency Level (Picklist Label)"] !== '' ? ' - ' + sf_skill["Proficiency Level (Picklist Label)"] : '')).trim().replace(/\s+/g, " ");
                    // return parsedSkill === skill
                }))) {
                    return false;
                }
                
                return true;
            });

            if (possiblePeople.length > 0) {

                setResourceItems(possiblePeople.map((x: any) => ({ ...x.person, minutes: x.minutes, persNo: x.persNo, match: 0, minutesPerWeek: 0, skillPercentage: 0, availabilityPercentage: 0 })))

            } else {
                setResourceItems([])
            }

            setGridLoading(false);
            
        }, 1000))
    }

    function startDateChange(event: any, target: any) {
        setStartDate(target.value)
    }

    function endDateChange(event: any, target: any) {
        setEndDate(target.value)
    }

    function submit() {
        setDialogOpen(true);
    }

    function cancel() {
        setSkills([])
        setProject({} as ProjectAndClient)
        setResourceItems([])
        setSelectedResourceItems(new Array<ResourceItem>())
    }

    function dialogCancel() {
        setDialogOpen(false);
        setTimeSelection('Full Time')
        setComment("")
    }

    function dialogConfirm() {
        setDialogOpen(false);
        axios.post('https://resourcerequest.cognitusconsulting.com/submit', {
            comment,
            requester: window.location.hostname === 'localhost' ? 'lvanderzande@cognitusconsulting.com' : data?.profile.mail,
            project,
            selectedCompanies,
            selectedDepartments,
            selectedLocations,
            selectedResourceItems,
            selectedSkills,
            timeSelection,
            partTimePercentage,
            hoursPerWeek,
            startDate,
            endDate
        }).then((response) => {
            if (response.data.status === 'created')
                setAlert({...Object.assign({}, defaultAlert), success: true, content: (<div>Resource request created in JIRA. <a rel="noreferrer" target="_blank" href={`https://cognitus.atlassian.net/browse/${response.data.key}`}>{response.data.key}</a></div>)})
            else
                setAlert({...Object.assign({}, defaultAlert), success: true, content: (<div>Resource request sent for approval.</div>)})
            setShowAlert(true);
            setDepartments([])
            setLocations([])
            setSkills([])
            setCompanies([])
            setResourceItems([])
            setProjectSearchQuery("")
            setProject({} as any)
            setResourceTypeSelection(["Employee", "Contractorl"])
            setWeightSliderValue('50')
            setTimeSelection('Full Time')
            setComment("")
        }).catch(error => {
            console.error(error);
            setAlert({...Object.assign({}, defaultAlert), danger: true, content: 'Failed to submit resource request.'})
            setShowAlert(true);
        })
        
    }

    if (error && window.location.host !== 'localhost:53000') {
        console.error(error);
        return (
            <div className="resourceRequest error">
                <h1>This application only works when used inside the Teams App.</h1>
            </div>
        )
    } else {
        return (
            <div>
                {window.screen.width > 1000 ? (
                <div className="resourceRequest desktop">
                    <Alert style={{zIndex: 1001}}fitted visible={showAlert} success={alert.success} danger={alert.danger} warning={alert.warning} actions={alert.actions} content={alert.content} />
                    <Flex gap="gap.smaller" vAlign="start" hAlign="center">
                        <Flex column className="resourceRequestForm">
                            <Card fluid elevated className="card-no-hover" style={{width: '30em', position: 'fixed', left: '4em', height: 'auto'}} >
                                <CardBody>
                                    <Form>
                                        <FormDatepicker minDate={new Date()} defaultSelectedDate={startDate} inputPlaceholder="Resource Start Date..." onDateChange={startDateChange} label={'Start Date'}/>
                                        <FormDropdown
                                            fluid
                                            label={'Required Skills'}
                                            search={true}
                                            multiple={true}
                                            placeholder={'Select Skills'} 
                                            onChange={skillSearchValueChange}
                                            value={selectedSkills}
                                            items={props.uniqueSkills}
                                            //@ts-ignore
                                            renderItem={(component, props, children) => {
                                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                                    let skillSet = new Set<string>([...selectedSkills, props.key]);
                                                    setSkills([...skillSet.values()]); 
                                                    inputChange([...skillSet.values()], resourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies); 
    
                                                }} className={'dropdown-item'}>{props.key}</li>)
                                            }}
                                        />
                                        <FormDropdown
                                            fluid
                                            label={'Location'}
                                            search={true}
                                            multiple={true}
                                            placeholder={'Select Location'} 
                                            onChange={locationSearchValueChange}
                                            value={selectedLocations}
                                            items={props.uniqueLocations}
                                            //@ts-ignore
                                            renderItem={(component, props, children) => {
                                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                                    let locationSet = new Set<string>([...selectedLocations, props.key]);
                                                    setLocations([...locationSet.values()]); 
                                                    inputChange(selectedSkills, resourceTypeSelection, [...locationSet.values()], selectedDepartments,  selectedCompanies); 
    
                                                }} className={'dropdown-item'}>{props.key}</li>)
                                            }}
                                        />
                                        <FormDropdown
                                            fluid
                                            label={'Department'}
                                            search={true}
                                            multiple={true}
                                            placeholder={'Select Department'} 
                                            onChange={departmentSearchValueChange}
                                            value={selectedDepartments}
                                            items={props.uniqueDepartments}
                                            //@ts-ignore
                                            renderItem={(component, props, children) => {
                                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                                    let departmentSet = new Set<string>([...selectedDepartments, props.key]);
                                                    setDepartments([...departmentSet.values()]); 
                                                    inputChange(selectedSkills, resourceTypeSelection, selectedLocations, [...departmentSet.values()],  selectedCompanies); 
    
                                                }} className={'dropdown-item'}>{props.key}</li>)
                                            }}
                                        />
                                        {/* <FormDropdown
                                            fluid
                                            label={'Division'}
                                            search={true}
                                            multiple={true}
                                            placeholder={'Select Division'} 
                                            onChange={divisionSearchValueChange}
                                            value={selectedDivisions}
                                            items={props.uniqueDivisions}
                                            //@ts-ignore
                                            renderItem={(component, props, children) => {
                                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                                    let divisionSet = new Set<string>([... props.key]);
                                                    setDivisions([...divisionSet.values()]); 
                                                    inputChange(selectedSkills, resourceTypeSelection, selectedLocations, selectedDepartments, [...divisionSet.values()], selectedCompanies); 
    
                                                }} className={'dropdown-item'}>{props.key}</li>)
                                            }}
                                        /> */}
                                        <FormDropdown
                                            fluid
                                            label={'Company'}
                                            search={true}
                                            multiple={true}
                                            placeholder={'Select Company'} 
                                            onChange={companySearchValueChange}
                                            value={selectedCompanies}
                                            items={props.uniqueCompanies}
                                            //@ts-ignore
                                            renderItem={(component, props, children) => {
                                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                                    let companySet = new Set<string>([...selectedCompanies, props.key]);
                                                    setCompanies([...companySet.values()]); 
                                                    inputChange(selectedSkills, resourceTypeSelection, selectedLocations, selectedDepartments,  [...companySet.values()]); 
    
                                                }} className={'dropdown-item'}>{props.key}</li>)
                                            }}
                                        />
                                        <Flex style={{width: '100%', paddingLeft: '.2em'}} inline hAlign="start" vAlign="center" gap="gap.smaller">
                                            <FormSlider fluid label="Skill vs Availibility Weight" defaultValue={50} min={1} onChange={(_event, data) => setWeightSliderValue(data!.value)} />
                                            <Label content={weightSliderValue} />
                                        </Flex>
                                        <FormCheckbox label="Employee" defaultChecked={true} onChange={(props, data) => {
                                            let newResourceTypeSelection = [...resourceTypeSelection];
                                            let index = newResourceTypeSelection.indexOf('Employee');
                                            if (data!.checked) {
                                                if (index === -1)
                                                    newResourceTypeSelection.push('Employee')
                                            } else {
                                                if (index !== -1)
                                                delete newResourceTypeSelection[index]
                                            }
                                            setResourceTypeSelection(newResourceTypeSelection)
                                            inputChange(selectedSkills, newResourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies)
                                        }}/>
                                        <FormCheckbox label="Contractor" defaultChecked={true} onChange={(props, data) => {
                                            let newResourceTypeSelection = [...resourceTypeSelection];
                                            let index = newResourceTypeSelection.indexOf('Contractor');
                                            if (data!.checked) {
                                                if (index === -1)
                                                    newResourceTypeSelection.push('Contractor')
                                            } else {
                                                if (index !== -1)
                                                    delete newResourceTypeSelection[index]
                                            }
                                            setResourceTypeSelection(newResourceTypeSelection)
                                            inputChange(selectedSkills, newResourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies)
                                        }}/>
                                        {/* <FormRadioGroup onCheckedValueChange={(_event, data) => { resourceTypeSelectionChange(data!.value as string) }} label="Resource Type" vertical defaultCheckedValue="Any" items={
                                            [
                                                { name: "Any", key: "Any", label: "Any", value: "Any" },
                                                { name: "Employee", key: "Employee", label: "Employee", value: "Employee" }, 
                                                { name: "Contractor", key: "Contractor", label: "Contractor", value: "Contractor" }
                                            ]
                                        } /> */}
                                        <div>
                                            <Button style={{margin: '1em'}} onClick={cancel}>Clear</Button>
                                            <Button primary style={{margin: '1em'}} disabled={
                                                selectedSkills.length === 0 &&
                                                selectedCompanies.length === 0 &&
                                                selectedLocations.length === 0 &&
                                                selectedDepartments.length === 0
                                            } onClick={submit}>Request</Button>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Flex>
                        {
                            resourceItems.length > 0 ? (
                                <Flex column style={{marginLeft: '30em'}}>
                                    <ResourceRequestGrid 
                                        photoMap={props.photoMap}
                                        weightSliderValue={weightSliderValue}
                                        startDate={startDate}
                                        gridLoading={gridLoading}
                                        setDialogOpen={setDialogOpen}
                                        resources={resourceItems}
                                        selectedSkills={selectedSkills}
                                        onChange={resourceItemSelectedChange}
                                    />
                                </Flex>) : <></>
                        }
                    </Flex>
                    
    
                    <Dialog
                        backdrop={false}
                        closeOnOutsideClick={false}
                        cancelButton="Cancel"
                        confirmButton="Confirm"
                        content={(
                            <div>
                                <FormDropdown
                                    label={'Project'}
                                    fluid
                                    search={true}
                                    multiple={false}
                                    open={projectSearchOpen}
                                    placeholder={ 'Request for Project' } 
                                    onChange={ (event, { value }) => {
                                        if (value !== null) {
                                            setProject((value as any).project)
                                        }
                                    } } 
                                    onOpenChange={(e, { open, value}) => {
                                        if (value && value === projectSearchQuery) {
                                            return
                                        }
                                    
                                        setProjectSearchOpen(open!)
                                    }}
                                    onSearchQueryChange={(event, data) => {
                                        if (data.searchQuery !== null && data.searchQuery !== '') {
                                            setProjectSearchQuery((data as any).searchQuery)
                                        } else {
                                            setProjectSearchQuery('')
                                            setProject({} as ProjectAndClient);
                                        }
                                        
                                    }}
                                    searchQuery={projectSearchQuery}
                                    items={props.projects.sort((project1: ProjectAndClient, project2: ProjectAndClient) => (project1["Project Name"] + ' - ' + project1["Project External"]).localeCompare(project2["Project Name"] + ' - ' + project2["Project External"])).map((p: ProjectAndClient) => ({ project: p, key: p["Project Name"]+' - '+p["Project External"], header: p["Project Name"] + ' - ' + p["Project External"]}))}
                                    //@ts-ignore
                                    renderItem={(component, props, children) => {
                                        return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                            setProject(props.project); 
                                            setProjectSearchQuery(props.header)
                                            setProjectSearchOpen(false);
                                        }} className={'dropdown-item'}>{props.header}</li>);
                                    }}
                                />
                                <br />
                                <FormDropdown
                                    fluid
                                    label={'Department'}
                                    search={true}
                                    multiple={true}
                                    placeholder={'Select Department'} 
                                    onChange={(props: any, target: any) => {
                                        let selectedDepartments = [...target.value]
                                        setDepartments(selectedDepartments)
                                    }}
                                    value={selectedDepartments}
                                    items={props.uniqueDepartments}
                                    //@ts-ignore
                                    renderItem={(component, props, children) => {
                                        return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                            let departmentSet = new Set<string>([...selectedDepartments, props.key]);
                                            setDepartments([...departmentSet.values()]); 
                                            // inputChange(selectedSkills, resourceTypeSelection, selectedLocations, [...departmentSet.values()],  selectedCompanies); 

                                        }} className={'dropdown-item'}>{props.key}</li>)
                                    }}
                                />
                                { project["Project Name"] !== undefined ? (<p>Project: { project["Project Name"] + ' - ' +  project["Project External"]}</p>) : (<></>) }
                                <p>Start Date <FormDatepicker minDate={new Date()} defaultSelectedDate={startDate} inputPlaceholder="Resource Start Date..." onDateChange={startDateChange} /></p>
                                <p>End Date <FormDatepicker minDate={new Date()} defaultSelectedDate={endDate} inputPlaceholder="Resource End Date..." onDateChange={endDateChange} /> </p>
                                <div>
                                    Selected Skills:
                                    <ul>
                                        { selectedSkills.map((skill, index) => (<li key={index}>{skill}</li>)) }
                                    </ul>
                                </div>
                                { selectedLocations.length > 0 ? (<div>Location:<ul> {selectedLocations.map((entry, index) => {
                                    return (<li key={index}>{entry}</li>)
                                })}</ul></div>) : (<></>) }
                                { selectedDepartments.length > 0 ? (<div>Departments:<ul> {selectedDepartments.map((entry, index) => {
                                    return (<li key={index}>{entry}</li>)
                                })}</ul></div>) : (<></>) }
                                { selectedCompanies.length > 0 ? (<div>Companies:<ul> { selectedCompanies.map((entry, index) => {
                                    return (<li key={index}>{entry}</li>)
                                }) }</ul></div>) : (<></>) }
    
                                {
                                    selectedResourceItems.length > 0 ? (<div>
                                        Selected Resources:
                                        <ul>
                                            { selectedResourceItems.map((resourceItem: ResourceItem, index) => (<li key={index}>{resourceItem.skills[0]["First Name"] + " " + resourceItem.skills[0]["Last Name"] + ' <' + resourceItem.skills[0].Email.toLowerCase() + '>'}</li>))}
                                        </ul>
                                    </div>) : <><strong>No Resources Selected. Talent Requisition Required.</strong></>
                                }
                                <FormRadioGroup
                                    vertical
                                    defaultCheckedValue="fulltime"
                                    items={[
                                        {
                                          name: 'fulltime',
                                          key: 'Full Time',
                                          label: 'Full Time',
                                          value: 'Full Time',
                                        },
                                        {
                                          name: 'parttime',
                                          key: 'Part Time',
                                          label: 'Part Time',
                                          value: 'Part Time',
                                        },
                                        {
                                          name: 'hoursperweek',
                                          key: 'Hours Per Week',
                                          label: 'Hours Per Week',
                                          value: 'Hours Per Week',
                                        },
                                      ]}
                                    onCheckedValueChange={(e, props) => {
                                        setTimeSelection((props as any).value)
                                    }}
                                />
                                <br/>
                                <br/>
                                {
                                    timeSelection === 'Part Time' ? (<FormSlider label="Part time Percentage" min={0} max={100} value={partTimePercentage} onChange={(e: any, props: any) => {
                                        setPartTimePercentage(props.value)
                                    }}/>) : timeSelection === 'Hours Per Week' ? (<FormInput width={'5em'} label="Hours Per Week" max={24 * 5} min={0} type="number" onChange={(e: any, props: any) => {
                                        let value = props.value
                                        if (value > 24 * 5) {
                                            value = 24 * 5
                                        }
                                        setHoursPerWeek(value)
                                    }} value={hoursPerWeek}/>) : <></>
                                }
                                <br/>
                                <br/>
                                <FormTextArea fluid label="Comment" onChange={(event: any, data: any) => {
                                    setComment(data.value)
                                }}/>
                                
                            </div>
                        )}
                        header="Confirm Resource Request"
                        onConfirm={dialogConfirm}
                        onCancel={dialogCancel}
                        open={dialogOpen}
                    />
                    
                </div>) : (
                // MOBILE
                <div className="resourceRequest mobile">
                <Alert style={{zIndex: 1001}} fitted visible={showAlert} success={alert.success} danger={alert.danger} warning={alert.warning} actions={alert.actions} content={alert.content} />
                    <Flex vAlign="center" hAlign="center" column className="resourceRequestForm">
                    <Form>
                        <FormDatepicker minDate={new Date()} defaultSelectedDate={startDate} inputPlaceholder="Resource Start Date..." onDateChange={startDateChange} label={'Start Date'}/>
                        <FormDropdown
                            fluid
                            label={'Required Skills'}
                            search={true}
                            multiple={true}
                            placeholder={'Select Skills'} 
                            onChange={skillSearchValueChange}
                            value={selectedSkills}
                            items={props.uniqueSkills}
                            //@ts-ignore
                            renderItem={(component, props, children) => {
                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                    let skillSet = new Set<string>([...selectedSkills, props.key]);
                                    setSkills([...skillSet.values()]); 
                                    inputChange([...skillSet.values()], resourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies); 

                                }} className={'dropdown-item'}>{props.key}</li>)
                            }}
                        />
                        <FormDropdown
                            fluid
                            label={'Location'}
                            search={true}
                            multiple={true}
                            placeholder={'Select Location'} 
                            onChange={locationSearchValueChange}
                            value={selectedLocations}
                            items={props.uniqueLocations}
                            //@ts-ignore
                            renderItem={(component, props, children) => {
                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                    let locationSet = new Set<string>([...selectedLocations, props.key]);
                                    setLocations([...locationSet.values()]); 
                                    inputChange(selectedSkills, resourceTypeSelection, [...locationSet.values()], selectedDepartments,  selectedCompanies); 

                                }} className={'dropdown-item'}>{props.key}</li>)
                            }}
                        />
                        <FormDropdown
                            fluid
                            label={'Department'}
                            search={true}
                            multiple={true}
                            placeholder={'Select Department'} 
                            onChange={departmentSearchValueChange}
                            value={selectedDepartments}
                            items={props.uniqueDepartments}
                            //@ts-ignore
                            renderItem={(component, props, children) => {
                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                    let departmentSet = new Set<string>([...selectedDepartments, props.key]);
                                    setDepartments([...departmentSet.values()]); 
                                    inputChange(selectedSkills, resourceTypeSelection, selectedLocations, [...departmentSet.values()],  selectedCompanies); 

                                }} className={'dropdown-item'}>{props.key}</li>)
                            }}
                        />
                        {/* <FormDropdown
                            fluid
                            label={'Division'}
                            search={true}
                            multiple={true}
                            placeholder={'Select Division'} 
                            onChange={divisionSearchValueChange}
                            value={selectedDivisions}
                            items={props.uniqueDivisions}
                            //@ts-ignore
                            renderItem={(component, props, children) => {
                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                    let divisionSet = new Set<string>([... props.key]);
                                    setDivisions([...divisionSet.values()]); 
                                    inputChange(selectedSkills, resourceTypeSelection, selectedLocations, selectedDepartments, [...divisionSet.values()], selectedCompanies); 

                                }} className={'dropdown-item'}>{props.key}</li>)
                            }}
                        /> */}
                        <FormDropdown
                            fluid
                            label={'Company'}
                            search={true}
                            multiple={true}
                            placeholder={'Select Company'} 
                            onChange={companySearchValueChange}
                            value={selectedCompanies}
                            items={props.uniqueCompanies}
                            //@ts-ignore
                            renderItem={(component, props, children) => {
                                return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                    let companySet = new Set<string>([...selectedCompanies, props.key]);
                                    setCompanies([...companySet.values()]); 
                                    inputChange(selectedSkills, resourceTypeSelection, selectedLocations, selectedDepartments,  [...companySet.values()]); 

                                }} className={'dropdown-item'}>{props.key}</li>)
                            }}
                        />
                        <Flex style={{width: '100%', paddingLeft: '.2em'}} inline hAlign="start" vAlign="center" gap="gap.smaller">
                            <FormSlider fluid label="Skill vs Availibility Weight" defaultValue={50} min={1} onChange={(_event, data) => setWeightSliderValue(data!.value)} />
                            <Label content={weightSliderValue} />
                        </Flex>
                        <FormCheckbox label="Employee" defaultChecked={true} onChange={(props, data) => {
                            let newResourceTypeSelection = [...resourceTypeSelection];
                            let index = newResourceTypeSelection.indexOf('Employee');
                            if (data!.checked) {
                                if (index === -1)
                                    newResourceTypeSelection.push('Employee')
                            } else {
                                if (index !== -1)
                                delete newResourceTypeSelection[index]
                            }
                            setResourceTypeSelection(newResourceTypeSelection)
                            inputChange(selectedSkills, newResourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies)
                        }}/>
                        <FormCheckbox label="Contractor" defaultChecked={true} onChange={(props, data) => {
                            let newResourceTypeSelection = [...resourceTypeSelection];
                            let index = newResourceTypeSelection.indexOf('Contractor');
                            if (data!.checked) {
                                if (index === -1)
                                    newResourceTypeSelection.push('Contractor')
                            } else {
                                if (index !== -1)
                                    delete newResourceTypeSelection[index]
                            }
                            setResourceTypeSelection(newResourceTypeSelection)
                            inputChange(selectedSkills, newResourceTypeSelection, selectedLocations, selectedDepartments,  selectedCompanies)
                        }}/>
                        {/* <FormRadioGroup onCheckedValueChange={(_event, data) => { resourceTypeSelectionChange(data!.value as string) }} label="Resource Type" vertical defaultCheckedValue="Any" items={
                            [
                                { name: "Any", key: "Any", label: "Any", value: "Any" },
                                { name: "Employee", key: "Employee", label: "Employee", value: "Employee" }, 
                                { name: "Contractor", key: "Contractor", label: "Contractor", value: "Contractor" }
                            ]
                        } /> */}
                        <div>
                            <Button style={{margin: '1em'}} onClick={cancel}>Clear</Button>
                            <Button primary style={{margin: '1em'}} disabled={
                                selectedSkills.length === 0 &&
                                selectedCompanies.length === 0 &&
                                selectedLocations.length === 0 &&
                                selectedDepartments.length === 0
                            } onClick={submit}>Request</Button>
                        </div>
                    </Form>
                    </Flex>
                    {
                        resourceItems.length > 0 ? (
                            <Flex >
                                <ResourceRequestGrid 
                                    photoMap={props.photoMap}
                                    weightSliderValue={weightSliderValue}
                                    startDate={startDate}
                                    gridLoading={gridLoading}
                                    setDialogOpen={setDialogOpen}
                                    resources={resourceItems}
                                    selectedSkills={selectedSkills}
                                    onChange={resourceItemSelectedChange}
                                />
                            </Flex>) : <></>
                    }
    
                    <Dialog
                        backdrop={false}
                        closeOnOutsideClick={false}
                        cancelButton="Cancel"
                        confirmButton="Confirm"
                        content={(
                            <div>
                                <FormDropdown
                                    label={'Project'}
                                    fluid
                                    search={true}
                                    multiple={false}
                                    open={projectSearchOpen}
                                    placeholder={ 'Request for Project' } 
                                    onChange={ (event, { value }) => {
                                        console.log(value);
                                        if (value !== null) {
                                            setProject((value as any).project)
                                        }
                                    } } 
                                    onOpenChange={(e, { open, value}) => {
                                        if (value && value === projectSearchQuery) {
                                            return
                                        }
                                    
                                        setProjectSearchOpen(open!)
                                    }}
                                    onSearchQueryChange={(event, data) => {
                                        if (data.searchQuery !== null && data.searchQuery !== '') {
                                            setProjectSearchQuery((data as any).searchQuery)
                                        } else {
                                            setProjectSearchQuery('')
                                            setProject({} as ProjectAndClient);
                                        }
                                        
                                    }}
                                    searchQuery={projectSearchQuery}
                                    items={props.projects.sort((project1: ProjectAndClient, project2: ProjectAndClient) => (project1["Project Name"] + ' - ' + project1["Project External"]).localeCompare(project2["Project Name"] + ' - ' + project2["Project External"])).map((p: ProjectAndClient) => ({ project: p, key: p["Project Name"]+' - '+p["Project External"], header: p["Project Name"] + ' - ' + p["Project External"]}))}
                                    //@ts-ignore
                                    renderItem={(component, props, children) => {
                                        return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                            setProject(props.project); 
                                            setProjectSearchQuery(props.header)
                                            setProjectSearchOpen(false);
                                        }} className={'dropdown-item'}>{props.header}</li>);
                                    }}
                                />
                                <br/>
                                <FormDropdown
                                    fluid
                                    label={'Department'}
                                    search={true}
                                    multiple={true}
                                    placeholder={'Select Department'} 
                                    onChange={(props: any, target: any) => {
                                        let selectedDepartments = [...target.value]
                                        setDepartments(selectedDepartments)
                                    }}
                                    value={selectedDepartments}
                                    items={props.uniqueDepartments}
                                    //@ts-ignore
                                    renderItem={(component, props, children) => {
                                        return (<li key={props.key} style={{padding: '.25em', borderRadius: '.25em'}} onClick={() => {
                                            let departmentSet = new Set<string>([...selectedDepartments, props.key]);
                                            setDepartments([...departmentSet.values()]); 
                                            // inputChange(selectedSkills, resourceTypeSelection, selectedLocations, [...departmentSet.values()],  selectedCompanies); 
                                        }} className={'dropdown-item'}>{props.key}</li>)
                                    }}
                                />
                                { project["Project Name"] !== undefined ? (<p>Project: { project["Project Name"] + ' - ' +  project["Project External"]}</p>) : (<></>) }
                                <p>Start Date <FormDatepicker minDate={new Date()} defaultSelectedDate={startDate} inputPlaceholder="Resource Start Date..." onDateChange={startDateChange} /></p>
                                <p>End Date <FormDatepicker minDate={new Date()} defaultSelectedDate={endDate} inputPlaceholder="Resource End Date..." onDateChange={endDateChange} /> </p>
                                <div>
                                    Selected Skills:
                                    <ul>
                                        { selectedSkills.map((skill, index) => (<li key={index}>{skill}</li>)) }
                                    </ul>
                                </div>
                                { selectedLocations.length > 0 ? (<div>Location:<ul> {selectedLocations.map((entry, index) => {
                                    return (<li key={index}>{entry}</li>)
                                })}</ul></div>) : (<></>) }
                                { selectedDepartments.length > 0 ? (<div>Departments:<ul> {selectedDepartments.map((entry, index) => {
                                    return (<li key={index}>{entry}</li>)
                                })}</ul></div>) : (<></>) }
                                { selectedCompanies.length > 0 ? (<div>Companies:<ul> { selectedCompanies.map((entry, index) => {
                                    return (<li key={index}>{entry}</li>)
                                }) }</ul></div>) : (<></>) }
    
                                {
                                    selectedResourceItems.length > 0 ? (<div>
                                        Selected Resources:
                                        <ul>
                                            { selectedResourceItems.map((resourceItem: ResourceItem, index) => (<li key={index}>{resourceItem.skills[0]["First Name"] + " " + resourceItem.skills[0]["Last Name"] + ' <' + resourceItem.skills[0].Email.toLowerCase() + '>'}</li>))}
                                        </ul>
                                    </div>) : <><strong>No Resources Selected. Talent Requisition Required.</strong></>
                                }
                                <FormRadioGroup
                                    vertical
                                    defaultCheckedValue="fulltime"
                                    items={[
                                        {
                                          name: 'fulltime',
                                          key: 'Full Time',
                                          label: 'Full Time',
                                          value: 'Full Time',
                                        },
                                        {
                                          name: 'parttime',
                                          key: 'Part Time',
                                          label: 'Part Time',
                                          value: 'Part Time',
                                        },
                                        {
                                          name: 'hoursperweek',
                                          key: 'Hours Per Week',
                                          label: 'Hours Per Week',
                                          value: 'Hours Per Week',
                                        },
                                      ]}
                                    onCheckedValueChange={(e, props) => {
                                        setTimeSelection((props as any).value)
                                    }}
                                />
                                <br/>
                                <br/>
                                {
                                    timeSelection === 'Part Time' ? (<FormSlider label="Part time Percentage" min={0} max={100} value={partTimePercentage} onChange={(e: any, props: any) => {
                                        setPartTimePercentage(props.value)
                                    }}/>) : timeSelection === 'Hours Per Week' ? (<FormInput width={'5em'} label="Hours Per Week" max={24 * 5} min={0} type="number" onChange={(e: any, props: any) => {
                                        let value = props.value
                                        if (value > 24 * 5) {
                                            value = 24 * 5
                                        }
                                        setHoursPerWeek(value)
                                    }} value={hoursPerWeek}/>) : <></>
                                }
                                <br/>
                                <br/>
                                <FormTextArea fluid label="Comment" onChange={(event: any, data: any) => {
                                    setComment(data.value)
                                }}/>
                                
                            </div>
                        )}
                        header="Confirm Resource Request"
                        onConfirm={dialogConfirm}
                        onCancel={dialogCancel}
                        open={dialogOpen}
                    />
                </div> ) }
            </div>
        )
    }

    
}


export default ResourceRequest