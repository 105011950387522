import React from "react";
/**
 * This component is used to display the required
 * terms of use statement which can be found in a
 * link in the about tab.
 */
export default function TermsOfUse() {
  return (
    <div>
      <h1>Terms of Use</h1>
    </div>
  );
}
