import { useEffect, useState } from "react";
import { ResourceItem } from "../../interface";
import ResourceRequestGridItem from "./ResourceRequestGridItem";
import moment from 'moment'
import { Flex, Loader } from "@fluentui/react-northstar";

export const chunk = (arr: any[], size: number) =>
    Array.from({ length: Math.ceil(arr.length / size) }, (_: any, i: number) =>
        arr.slice(i * size, i * size + size)
    );

const maxMinutesAWeek = 60 * 8 * 5;

const ResourceRequestGrid = (props: any) => {

    const [selectedItems, setSelectedItems] = useState<Map<number, ResourceItem>>(new Map<number, ResourceItem>());
    const [mappedResources, setMappedResources] = useState<Map<number, ResourceItem>>(new Map<number, ResourceItem>());


    function onSelect(item: ResourceItem) {

        selectedItems.set(item.persNo, item)
        setSelectedItems(selectedItems)
        props.onChange([...selectedItems.values()])

    }

    function onDeselect(item: ResourceItem) {
        selectedItems.delete(item.persNo)
        setSelectedItems(selectedItems)
        props.onChange([...selectedItems.values()])
    }

    useEffect(() => {
        setMappedResources(props.resources.map((resourceItem: ResourceItem) => {

            resourceItem.skillPercentage = props.selectedSkills.length > 0 ? (props.selectedSkills.filter((skill: string) => resourceItem.uniqueSkills.includes(skill)).length / props.selectedSkills.length) * 100 : 100

            resourceItem.minutesPerWeek = chunk(resourceItem.minutes.slice(Math.ceil(moment(props.startDate).diff(moment(), 'days', true))), 5).map((inner: number[]) => inner.reduce((a: number, b: number) => a + b, 0)).map(num => (num).toFixed(2)).slice(0, 8);

            while (resourceItem.minutesPerWeek.length < 8) {
                resourceItem.minutesPerWeek.push("0.0")
            }

            let averageMinutesPerWeek = resourceItem.minutesPerWeek.reduce((a: number, b: string) => a + Number.parseFloat(b), 0.0) / resourceItem.minutesPerWeek.length

            let minutesPercentage = (averageMinutesPerWeek / maxMinutesAWeek);


            resourceItem.availabilityPercentage = Number.parseFloat((1 - minutesPercentage).toFixed(2)) * 100;

            let skillWeight = (100 - props.weightSliderValue) / 100
            let availabilityWeight = props.weightSliderValue / 100

            let weightedSkill = resourceItem.skillPercentage * skillWeight;
            let weightedAvail = (resourceItem.availabilityPercentage) * availabilityWeight;

            resourceItem.match = Number.parseFloat(((weightedSkill + weightedAvail)).toFixed(2));

            return resourceItem;
        }
        ).sort((a: ResourceItem, b: ResourceItem) => b.match - a.match))
    }, [props.resources, props.selectedSkills, props.startDate, props.weightSliderValue])



    return (
        <div style={{ width: '100%', height: '100%', alignItems: 'center', alignContent: 'center', textAlign: 'center', 'transition': 'all .3s ease' }}>
            {
                props.gridLoading ? (<Flex vAlign="center" hAlign="center"><Loader style={{ textAlign: 'center', display: 'block' }} /></Flex>) : (
                    <></>
                )
            }
            <div>
                {
                    [...mappedResources.values()].map((resourceItem: ResourceItem, index: number) => {
                        return (<ResourceRequestGridItem
                            className="resource-request-grid-item" 
                            onSelect={onSelect} 
                            onDeselect={onDeselect} 
                            photo={props.photoMap[resourceItem.skills[0].Email.toLowerCase()] === 'defaultPhotoData' ? props.photoMap['defaultPhotoData'] : props.photoMap[resourceItem.skills[0].Email.toLowerCase()]} 
                            loading={props.gridLoading} 
                            selectedSkills={props.selectedSkills} 
                            resourceItem={resourceItem} 
                            key={index} 
                        />)
                    })
                }
            </div>

        </div>

    )
}

export default ResourceRequestGrid