import { useState } from "react";

import { Card, CardHeader, Flex, CardBody, 
  Text,
  Checkbox,
  Button,
  BriefcaseIcon,
  CalendarIcon,
  Tooltip,
  ChevronDownIcon,
} from "@fluentui/react-northstar"
import { ResourceItem } from "../../interface";
import AssignmentHeatmap from "../AssignmentHeatmap/AssignmentHeatmap";

import CircularPercentage from "../circular-percentage/CircularPercentage"


const myColor = (x: number) => `${Math.min(255,2*255*(1-x))}, ${Math.min(255,2*255*(x))}, 0`;
const percentageColor = (percentage: number) => myColor(percentage / 100)

const ResourceRequestGridItem = (props: any) => {

    const resourceItem: ResourceItem = props.resourceItem;

    const [selected, setSelected] = useState<boolean>(false);
    const [view, setView] = useState<string>('');
    const [showSkills, setShowSkills] = useState<boolean>(false)

    let fullName = resourceItem.skills[0]["First Name"] + ' ' + resourceItem.skills[0]["Last Name"];

    return (
        <Card style={{
                WebkitBoxShadow: '0px 0px 3px 1px rgba(0,0,0,0.28)',
                MozBoxShadow: '0px 0px 3px 1px rgba(0,0,0,0.28)',
                boxShadow: '0px 0px 3px 1px rgba(0,0,0,0.28)',
                backgroundColor: 'rgba(255, 255, 255, .5)', 
                minHeight: '5em', 
                height: 'auto', 
                margin: '.5em',
                width: 'calc(100% - 2em)', 
                maxWidth: '30em', 
                display: 'inline-block', 
                border: 'none'
            }} >
            <Card.TopControls>
                <Tooltip content={!selected ? 'Select' : 'Deselect'} trigger={<Checkbox onChange={(_props, data) => {
                    setSelected(data!.checked)
                    if (data!.checked) {
                        props.onSelect(resourceItem)
                    } else {
                        props.onDeselect(resourceItem)
                    }
                }} />}/>
                <Tooltip content={view !== 'assignments' ? 'View assignments' : 'Hide assignments'} trigger={<Button  onClick={(event, data) => {
                    setView(view === 'assignments' ? '' : 'assignments')
                }} text primary={view === 'assignments'} iconOnly icon={<BriefcaseIcon color={view === 'assignments' ? 'rgb(91, 95, 199)' : ''} />}></Button>}/>
                <Tooltip content={view !== 'availability' ? 'View availability' : 'Hide availability'} trigger={<Button color={view === 'availability' ? 'rgb(91, 95, 199)' : ''} onClick={(event, data) => {
                    setView(view === 'availability' ? '' : 'availability')
                }} text primary={view === 'availability'} iconOnly icon={<CalendarIcon color={view === 'availability' ? 'rgb(91, 95, 199)' : '#000'}/>}></Button>}/>
            </Card.TopControls>
            <CardHeader>
                <Flex gap="gap.small">
                    <Tooltip content={resourceItem.match + '% match'} trigger={
                            <Flex column>
                                {/* <Avatar
                                    size={"large"}
                                    image={props.photo}
                                /> */}
                                <Flex>
                                    <CircularPercentage scale=".75" image={props.photo} percentageFill={`rgba(0, 0, 0, .5)`} percentage={resourceItem.match} color={`rgba(${percentageColor(resourceItem.match)}, 1)`}/>
                                </Flex>
                            </Flex>
                        }
                    />
                    <Flex column style={{textAlign: 'left'}}>
                        
                        <Text content={fullName} size={fullName.length > 25 ? 'smaller' : 'medium'} weight="bold" />
                        <Text content={resourceItem.skills[0]["Global Job Title"].replaceAll('_', ' ') } size={resourceItem.skills[0]["Global Job Title"].length > 25 ? 'smaller' : 'small'} />
                        { resourceItem.startEnd !== undefined ? (<Text content={resourceItem.startEnd["Start Date"] } size={resourceItem.startEnd["Start Date"].length > 20 ? 'smaller' : 'small'} />) : <></> }
                        <Text content={resourceItem.skills[0]["Location (Name)"] } size={resourceItem.skills[0]["Location (Name)"].length > 20 ? 'smaller' : 'small'} />
                        <Text content={resourceItem.skills[0]["Employment Type (Picklist Label)"] } size={resourceItem.skills[0]["Employment Type (Picklist Label)"].length > 20 ? 'smaller' : 'small'} />

                        {/* <Text content='Lars van der Zande' weight="bold" />
                        <Text content='System Administrator' size="small" /> */}
                    </Flex>
                </Flex>
            </CardHeader>
            <CardBody style={{textAlign: 'left'}}>
                <Flex gap="gap.small">
                    <Flex column>
                        {
                            resourceItem.uniqueSkills.length > 0 ?
                            (<div>
                            
                                {
                                    !showSkills ?
                                    <ChevronDownIcon style={{cursor: 'pointer'}} rotate={180} onClick={() => {
                                        setShowSkills(true)
                                    }}/> :
                                    <ChevronDownIcon style={{cursor: 'pointer'}} onClick={() => {
                                        setShowSkills(false)
                                    }}/>
                                }
                                <span style={{cursor: 'pointer'}} onClick={() => {
                                    setShowSkills(!showSkills)
                                }}>&nbsp;Skills ({props.selectedSkills.length > 0 ? (props.selectedSkills.filter((skill: string) => resourceItem.uniqueSkills.includes(skill)).length) + '/' + props.selectedSkills.length : resourceItem.uniqueSkills.length})</span>
                                {
                                    showSkills ?
                                        (<ul style={{fontSize: '.8em'}}>
                                        {
                                            props.selectedSkills.length > 0 ? 
                                            props.selectedSkills.filter((skill: string) => resourceItem.uniqueSkills.includes(skill)).map((skill: string, index: number) => {
                                                return (<li key={index} style={{color: '#0a0'}}>{skill}</li>)
                                            }) :
                                            resourceItem.uniqueSkills.map((skill: string, index: number) => {
                                                return (<li key={index} style={{color: ''}}>{skill}</li>)
                                            })
                                        }
                                    </ul>) : (<></>)
                                }
                            </div>) : (<></>)
                        }
                        
                        
                        
                    </Flex>
                    
                </Flex>
                <AssignmentHeatmap series={[{ name: 'minutes', data: resourceItem.minutesPerWeek }]} />
            </CardBody>
        </Card>
    )
}

export default ResourceRequestGridItem