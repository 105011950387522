import ReactApexChart from 'react-apexcharts'

const maxMinutesAWeek = 60 * 8 * 5;


export default function AssignmentHeatmap(props: any) {
    function color({value, seriesIndex, w}: any) {
        let ratio = parseFloat(value as string) / maxMinutesAWeek
        if (ratio < .25)
            return '#4ea832'
        if (ratio < .5)
            return '#a8a432'
        if (ratio < .75)
            return '#a2a832'
        if (ratio < 1)
            return '#a87532'
        return '#a84432'
    }
    const defaultOptions = {
        colors: [color],
        chart: {
            
            type: 'heatmap',
            toolbar: {
                show: false
            }
        },
        tooltip: {
            enabled: true
        },
        dataLabels: {
            enabled: false
        },
        yaxis: {
            show: false
        },
        plotOptions: {
            heatmap: {
                radius: 2,
                colorScale: {
                    ranges: [
                        {
                            from: 2400,
                            to: 10000,
                            color: '#a84432',
                            name: 'overbooked'
                        },
                        {
                            from: 1801,
                            to: 2400,
                            color: '#a87532',
                            name: 'low'
                        },
                        {
                            from: 1201,
                            to: 1800,
                            color: '#a2a832',
                            name: 'medium'
                        },
                        {
                            from: 601,
                            to: 1200,
                            color: '#a8a432',
                            name: 'high'
                        },
                        {
                            from: 0,
                            to: 600,
                            color: '#4ea832',
                            name: 'underbooked'
                        }
                    ]
                }
            }
        },
        xaxis: {
            type: 'category',
            title: {
                show: false,
                text: undefined
            },
            categories: ['W1', 'W2', 'W3', 'W4', 'W5', 'W6', 'W7', 'W8']
        },
        grid: {
            padding: {
                right: 20
            }
        }
    }

    return (
        <div id="chart">
            {/* @ts-ignore */}
            <ReactApexChart options={{...defaultOptions, ...props.options}} series={props.series} type="heatmap" height={75} width={'100%'} />
        </div>
    )
}