import { useContext } from "react";
// import { Welcome } from "./sample/Welcome";
import ResourceRequest from "./resourcerequest/ResourceRequest";
import { TeamsFxContext } from "./Context";
import { RunnClient } from "runn-sdk";
import { Assignment, Cost, Data, Person, ProjectAndClient, Skill, StartEnd } from "../interface";




export default function Tab(props: any) {
  const { themeString } = useContext(TeamsFxContext);
  const data = props.data as Data;
  return (
    <div className={themeString === "default" ? "" : "dark"}>
      <ResourceRequest 
        people={ data.people as Array<{persNo: number, person: Person}> }
        projects={ data.projects as Array<ProjectAndClient> } 
        runnClient={ props.runnClient as RunnClient }
        assignments={ data.assignments as Array<Assignment> }
        startEnd={ data.startEnd as Array<StartEnd> }
        costRates={ data.costRates as Array<Cost> }
        skills={ data.skills as Array<Skill> }
        uniqueSkills={ data.uniqueSkills as Array<string> }
        uniqueLocations={ data.uniqueLocations as Array<string> }
        uniqueDepartments={ data.uniqueDepartments as Array<string> }
        uniqueDivisions={ data.uniqueDivisions as Array<string> }
        uniqueCompanies={ data.uniqueCompanies as Array<string> }
        photoMap={ data.photoMap as { [email: string]: string } }
      />
    </div>
  );
}
