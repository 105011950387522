// https://fluentsite.z22.web.core.windows.net/quick-start
import { Provider, teamsTheme, Loader } from "@fluentui/react-northstar";
import { HashRouter as Router, Redirect, Route } from "react-router-dom";
import { useTeamsFx } from "@microsoft/teamsfx-react";
import Privacy from "./Privacy";
import TermsOfUse from "./TermsOfUse";
import Tab from "./Tab";
import "./App.css";
import TabConfig from "./TabConfig";
import { TeamsFxContext } from "./Context";
import axios from 'axios'

import { useEffect, useState } from "react";
import { Data } from "../interface";


// const businessDaysInMonth = (
//   (year: number, month: number) => 
//       new Array(32 - new Date(year, month, 32).getDate())
//       .fill(1)
//       .filter(
//           (id, index) =>
//               [0, 6].indexOf(
//                   new Date(year, month, index + 1).getDay()) === -1
//               ).length
// );




/**
 * The main app which handles the initialization and routing
 * of the app.
 */
export default function App() {

  const [data, setData] = useState<any>({} as Data);
  const [loadingData, setLoadingData] = useState<boolean>(false)

  useEffect(() => {
    async function getData() {
      setLoadingData(true);
      let data = (await axios.get('https://resourcerequest.cognitusconsulting.com/data')).data;
      setData(data)
      setLoadingData(false);
    }
    getData();
  }, []);

  const { loading, theme, themeString, teamsfx } = useTeamsFx(
    {
      "initiateLoginEndpoint": "https://resourcerequest.cognitusconsulting.com/auth-start.html",
      "applicationIdUri": "https://resourcerequest.cognitusconsulting.com/auth-start.html",
      "clientId": 'f97b9044-d6af-4f97-ab3b-9789f158974e',
    }
  );
  
  return (
    <TeamsFxContext.Provider value={{theme, themeString, teamsfx}}>
      <Provider theme={theme || teamsTheme} styles={{ backgroundColor: "#eeeeee" }}>
        <Router>
          <Route exact path="/">
            <Redirect to="/tab" />
          </Route>
          {(loading || loadingData) ? (
            <Loader style={{ margin: 100 }} />
          ) : (
            <>
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/termsofuse" component={TermsOfUse} />
              <Route exact path="/tab" component={() => <Tab data={ data } />} />
              <Route exact path="/config" component={TabConfig} />
            </>
          )}
        </Router>
      </Provider>
      </TeamsFxContext.Provider>
  );
}
