import { Avatar } from '@fluentui/react-northstar';
import './CircularPercentage.css'

export default function CircularPercentage(props: any) {
    return (
        <div className="flex-wrapper">
            <Avatar style={{ position: 'absolute', height: '44px', width: '44px', 'margin': '1em'}} size="large" image={props.image}></Avatar>
            <div className="single-chart" style={{height: '72px', width: '72px'}}>
                <svg viewBox="0 0 36 36" transform={`scale(${props.scale})`} className="circular-chart">
                    <path className="circle-bg"
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                    <path className="circle"
                        stroke={props.color}
                        strokeDasharray={`${props.percentage}, 100`}
                        d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"
                    />
                </svg>
            </div>
        </div>
    );
}